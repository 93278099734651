<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'money-report.title' | transloco }}
      </h3>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="row">
            <div class="col-lg-3">
              <hx-date-range-select [(ngModel)]="dateRange" (selectChange)="applyFilters()"></hx-date-range-select>
            </div>
          </div>

          <div class="m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-20 m--margin-bottom-30">
            <table class="m-datatable__table" *ngIf="report && !isLoading.list">
              <thead class="m-datatable__head">
              <tr class="m-datatable__row m-datatable__row--head">
                <th class="m-datatable__cell">
                  <span>#</span>
                </th>
                <th class="m-datatable__cell">
                  <span>{{ 'money-report.fullName' | transloco }}</span>
                </th>
                <th class="m-datatable__cell text-right">
                  <span>{{ 'money-report.orders' | transloco }}</span>
                </th>
                <th class="m-datatable__cell text-right">
                  <span>{{ 'money-report.total' | transloco }}, {{ currencySymbol }}</span>
                </th>
                <th *ngFor="let cmn of columns" class="m-datatable__cell text-right">
                  {{'paymentType.' + cmn | transloco}}
                </th>
                <th class="m-datatable__cell text-right">
                  <span>{{ 'money-report.discount' | transloco }}, {{ currencySymbol }}</span>
                </th>
              </tr>
              </thead>
              <tbody class="m-datatable__body">
                <tr class="m-datatable__row" *ngIf="report">
                  <td class="m-datatable__cell">1</td>
                  <td class="m-datatable__cell">
                    <a *ngIf="report.user" [routerLink]="['full']"
                       [queryParams]="{sale: report.user.id, dateFrom: dateRange?.from, dateTo: dateRange?.to, storeId: currentStoreId }">
                      {{ report.user.lastname}} {{report.user.firstname}}
                    </a>
                  </td>

                  <td class="m-datatable__cell text-nowrap text-right" style="min-width: 100px; width: 15%;">
                    {{ report.count }}
                  </td>
                  <td class="m-datatable__cell text-nowrap text-right" style="min-width: 100px; width: 15%;">
                    <span>
                      {{ report.total.toLocaleString('ru-RU') }}
                    </span>
                  </td>
                  <td *ngFor="let col of columns;" class="m-datatable__cell text-nowrap text-right" style="min-width: 100px;">
                    <a *ngIf="report.paymentMap.get(col)?.value" [routerLink]="['full']"
                       [queryParams]="{sale: report.user?.id, dateFrom: dateRange?.from, dateTo: dateRange?.to, paymentType: col, storeId: currentStoreId }">
                      {{ report.paymentMap.get(col)?.value }}
                    </a>
                    <span *ngIf="!report.paymentMap.get(col)?.value">—</span>
                  </td>

                  <td class="m-datatable__cell text-nowrap text-right" style="min-width: 100px;">
                    <a *ngIf="report.totalDiscount && report.user" [routerLink]="['full']"
                       [queryParams]="{sale: report.user.id, dateFrom: dateRange?.from, dateTo: dateRange?.to, discount: true, storeId: currentStoreId }">
                      {{ report.totalDiscount.toLocaleString('ru-RU') }}
                    </a>
                    <span *ngIf="!report.totalDiscount">—</span>
                    <span
                      *ngIf="!report.user && report.totalDiscount">{{ report.totalDiscount.toLocaleString('ru-RU') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div *ngIf="!report && isLoading.list"
              class="m-page-loader m-page-loader--non-block m-page-loader--relative">
              <div class="m-loader m-loader--brand"></div>
            </div>
          </div>

          <h4 class="loader__title" *ngIf="!report && !isLoading.list">
            {{ 'money-report.noData' | transloco }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>
